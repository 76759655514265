body {
  background-color: #eaeaea;
}

.ui.fixed.menu {
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  );
}

.ui.main.container {
  margin-top: 7em;
}

.ui.segment.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  );
  height: 100vh;
}

.masthead h1.ui.header {
  font-size: 4em;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-calendar {
  width: 100%;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}
